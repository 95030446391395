@import '@crystal-eyes/constants.scss';

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1rem !important;
  line-height: 1.6em;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-family: var(--font-lato);
  font-weight: 400;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: $action-blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.6em;
}

textarea,
input {
  font-family: inherit;
}

// EXTERNAL OVERRIDES

// hide the feedback fetcher
#hs-feedback-fetcher {
  height: 0;
}

// expand the date pickers to their parent width
.react-datepicker-wrapper {
  width: 100%;
}
