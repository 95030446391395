@import '@crystal-eyes/constants.scss';

.container {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.error-sky {
  width: 100%;
  height: 300px;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-header {
  margin: 0px 16px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
}

.error-header-text {
  font-size: 42px;
  line-height: 60px;
  letter-spacing: 2px;
  font-family: var(--font-lato);
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 40px;
  }
}

.iceberg {
  height: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    opacity: 0.8;
    height: 450px;
    margin-bottom: -300px;
    animation: bob 10s ease infinite;
  }
}

.water-wrapper {
  opacity: 0.9;
  flex: 1;
  min-height: 200px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 90px,
    $mid-blue 110px,
    $mid-blue 100%
  );
}

.ocean {
  position: relative;
  width: 100%;
  z-index: 10;
  height: 110px;
  overflow: hidden;
}

.wave {
  background: url(https://cdn2.hubspot.net/hubfs/1716276/WebsiteApril2019/wave.svg)
    repeat-x;
  position: absolute;
  bottom: 0;
  width: 6400px;
  height: 198px;
  animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform-origin: bottom;
}

@keyframes bob {
  0% {
    transform: translateY(0) rotate(-2deg);
  }
  50% {
    transform: translateY(20px) rotate(2deg);
  }
  100% {
    transform: translateY(0) rotate(-2deg);
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
    transform: scaleY(0.3);
  }
  100% {
    margin-left: -1600px;
    transform: scaleY(0.3);
  }
}

.link {
  margin-top: 8px;
}
