@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.4s;
  transform-origin: center;
  max-width: 0px;
  &.show {
    animation: scaleIn 0.2s 1 ease;
    max-width: 500px;
    transform: scale(1);
  }
}

@keyframes widthIn {
  0% {
    max-width: 0px;
  }
  100% {
    max-width: 500px;
  }
}

.back {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $blue;
  z-index: 2;
  animation: scaleIn 0.3s 1 cubic-bezier(0, 0.65, 0.35, 1.2);
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));

  &.square {
    border-radius: 4px 0 0 4px;
  }
  &.green {
    background: $green;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.icon {
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: 100;
  animation: pop 0.7s 1 cubic-bezier(0, 0.65, 0.35, 1.5);
}

@keyframes pop {
  0% {
    transform: scale(0) rotate(-50deg);
  }
  30% {
    transform: scale(0) rotate(-50deg);
  }
  70% {
    transform: scale(1.3) rotate(5deg);
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.successMessage {
  background: #fff;
  transform-origin: left;
  color: $blue;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  font-family: var(--font-lato);
  font-weight: 700;
  padding: 6px 8px 6px 20px;
  margin-left: -12px;
  border-radius: 0 50px 50px 0;
  border: 1px solid $blue;
  z-index: 0;
  animation: slideIn 0.6s 1 cubic-bezier(0, 0.65, 0.35, 1.1);
  &.square {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 4px 4px 0;
  }
}

@keyframes slideIn {
  0% {
    transform: scaleX(0);
  }
  60% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
