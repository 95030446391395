@import '@crystal-eyes/constants.scss';

.container {
  background-color: $blue;
  padding: 12px 16px;
  margin: 8px 8px;
  border-radius: 100px;
  border: none;
  font-family: var(--font-lato);
  font-weight: 700;
  color: $white;
  letter-spacing: 2px;
  font-size: 12px;
  text-transform: uppercase;
  outline: none;
  text-decoration: none;
  display: inline-block;
  width: auto;
  text-align: center;
  cursor: pointer;
  line-height: 1em;
  transition: all 0.1s;
  user-select: none;

  &.no-margin {
    margin: 0;
  }

  &.disabled {
    background: #ddd !important;
    pointer-events: none;
    box-shadow: none !important;
  }

  &.outline {
    background-color: unset;
    border: 1px solid $material-54;
    color: $material-54;
    font-family: var(--font-lato);
    font-weight: 400;

    &:hover {
      border: 1px solid $blue;
    }
    &.disabled {
      border: 0px solid #fff;
      color: #fff;
      font-family: var(--font-lato);
      font-weight: 700;
    }
  }

  &.small {
    padding: 8px 12px;
    line-height: 12px;
  }

  &.red {
    background: $red;
    &:hover {
      background: lighten($red, 12);
    }
  }

  &.orange {
    background: $accent-orange;
    &:hover,
    &:active,
    &.focus {
      background: lighten($accent-orange, 12);
    }
  }

  &.midnight {
    background: lighten($crystal-midnight-blue, 12);
    &:hover,
    &:focus {
      background-color: lighten($crystal-midnight-blue, 12);
    }
  }

  &.white {
    background: #fff;
    color: $material-87;
    font-family: var(--font-lato);
    font-weight: 400;
    font-size: 14px;
    transition: all 0.3s cubic-bezier(0, 0.65, 0.35, 1.5);
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0);
    &:hover {
      background-color: $white;
      color: $material-87;
      transform: scale(1.05);
      filter: brightness(100%);
    }
  }

  &.actionBlue {
    background-color: $action-blue;

    &.outline {
      background-color: unset;
      border-color: $action-blue;
      color: $action-blue;
    }

    &:hover {
      background: $action-blue;
      color: $white;
    }

    &:active,
    &:focus {
      background-color: $action-blue;
    }
  }

  &.wide {
    padding: 10px 32px;
  }

  &.xWide {
    padding: 12px 32px;
  }

  &.inline {
    height: auto;
    margin: 8px auto 8px 0;
    display: inline-block;
    .label {
      font-size: 11px;
    }
  }

  &:hover,
  &:focus {
    background-color: $blue;
    color: $white;
    outline: none;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
    filter: brightness(108%);
    text-decoration: none;
  }

  &:active {
    box-shadow: none;
    color: $white;
    outline: none;
    transform: translateY(1px);
    text-decoration: none;
  }

  &.success {
    transition: all 0s !important;
    background-color: transparent !important;
    padding: 0;
    &:hover {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
    }
    &.disabled {
      filter: grayscale(1);
    }
  }

  &.thick {
    font-size: 14px;
    padding: 14px 40px;
  }

  &.darkBlue {
    background-color: #2f5fa5;
  }
  &.midBlue {
    background-color: #007fb6;
  }
  &.lightBlue {
    background-color: #00b6bc;
  }
}
